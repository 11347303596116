import React from "react";
import MainNavBar from "./MainNavBar";
import { graphql, StaticQuery } from "gatsby";
// import styles from "../styles/insurance.css";
import Footer from "./Footer";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
export const query = graphql`
  query insurance {
    ourServicesGatsby: file(relativePath: { eq: "updated-our-services.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    dataJson {
      insurance_title_deluxe
      insurance_left_title
      insurance_left_paragraph
      insurance_right_paragraph
    }
  }
`;

function Insurance({ data }) {
  const ourServicesGatsby = getImage(data.ourServicesGatsby);

  return (
    <div>
      <div>
        <MainNavBar />
      </div>
      <div className="about-us-cnt">
        <h1 className="about-us-title">
          {data.dataJson.insurance_title_deluxe}
        </h1>
      </div>

      <section className="main-box">
        <div className="main-about-us-cnt">
          <div className="text-card">
            <h3 className="who-are-we">
              {data.dataJson.insurance_left_title}
            </h3>
            <p className="about-us-paragrapgh">
              {data.dataJson.insurance_left_paragraph}
            </p>
          </div>
          <div className="img-card-services">
            <div className="team-pic-cnt">
              <div className="pic-cnt">
                <StaticImage
                  className="circle-img"
                  placeholder="blurred"
                  src="../images/slide-1.png"
                  alt="portrait"
                />
              </div>
              <div className="pic-cnt">
                <StaticImage
                  className="circle-img"
                  placeholder="blurred"
                  src="../images/slide-1.png"
                  alt="portrait"
                />
              </div>

              <div className="pic-cnt">
                <StaticImage
                  className="circle-img"
                  placeholder="blurred"
                  src="../images/slide-1.png"
                  alt="portrait"
                />
              </div>
              <div className="pic-cnt">
                <StaticImage
                  className="circle-img"
                  placeholder="blurred"
                  src="../images/slide-1.png"
                  alt="portrait"
                />
              </div>

              <div className="pic-cnt">
                <StaticImage
                  className="circle-img"
                  placeholder="blurred"
                  src="../images/slide-1.png"
                  alt="portrait"
                />
              </div>

              <div className="pic-cnt">
                <StaticImage
                  className="circle-img"
                  placeholder="blurred"
                  src="../images/slide-1.png"
                  alt="portrait"
                />
              </div>
            </div>
            <div className="serv-left-side-para">
              <p>{data.dataJson.insurance_right_paragraph}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="image-about-us-box">
        <div className="image-about-us-cnt">
          <div className="image-card">
            <GatsbyImage
              className="image-about-us-newPage"
              placeholder="blurred"
              image={ourServicesGatsby}
            />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Insurance;
